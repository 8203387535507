import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

const Score = ({ percentage, points }) => {
  return (
    <div className="result-bar">
      <div className="score-overlay">
        <span>
          <span className="result-points">{points}</span>
          <br />
          {points === 1
            ? 'tačan odgovor'
            : points === 2
            ? 'tačna odgovora'
            : points >= 3 && points <= 4
            ? 'tačna odgovora'
            : 'tačnih odgovora'}
        </span>
      </div>
      <CircularProgressbar
        value={percentage}
        styles={buildStyles({
          strokeLinecap: 'butt',
          pathColor: '#00594e',
        })}
      />
    </div>
  );
};

export default Score;
