// Import React stuff
import React, { useState } from 'react';

// Import actions, reducers
import { setShowOptin } from '../../store/userSlice';

// Import animation, motion
import { AnimatePresence, motion } from 'framer-motion';
import { fadeInOut, containerVariants, child } from '../../utils/animations';

// Import components
import { useDispatch, useSelector } from 'react-redux';
import { createUser } from '../../store/actions';

// Import helper functions
import { isValidEmail, validateForm } from '../../utils/helpers';
import { setShowResult } from '../../store/gameSlice';

const Optin = ({ introData }) => {
  const dispatch = useDispatch();

  const correctAnswers = useSelector((state) => state.games.correctAnswers);
  const correctAnswersNum = correctAnswers.filter((x) => x === true).length;
  const points = correctAnswersNum;

  const [formData, setFormData] = useState({
    fname: '',
    lname: '',
    email: '',
    subscribe: false,
  });

  // Validate email
  const [unvalidEmail, setUnvalidEmail] = useState(false);

  // Errors
  const [formErrors, setFormErrors] = useState({
    fname: false,
    lname: false,
    email: false,
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    const fieldValue = type === 'checkbox' ? checked : value;
    setFormData((prevData) => ({
      ...prevData,
      [name]: fieldValue,
    }));

    // Clear the corresponding error when the user starts typing or checks the checkbox
    if (name === 'fname' && formErrors.fname) {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        fname: false,
      }));
    }

    if (name === 'lname' && formErrors.lname) {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        lname: false,
      }));
    }

    if (name === 'email') {
      if (formErrors.email) {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          email: false,
        }));
      }

      // Check if the email is valid
      const isEmailValid = isValidEmail(value);
      setUnvalidEmail(!isEmailValid);
    }

    if (name === 'subscribe') {
      if (checked) {
        window.plausible('subscribe');
      }
    }
  };

  const handleUser = () => {
    // Perform form validation
    const formErrors = validateForm(formData);

    if (Object.keys(formErrors).length > 0) {
      setFormErrors(formErrors);
    } else {
      const isEmailValid = isValidEmail(formData.email);
      if (formData.email.trim() !== '' && !isEmailValid) {
        setUnvalidEmail(true);
      } else {
        // Save the form data or dispatch an action
        console.log('Form data:', formData);

        dispatch(createUser({ formData, points }))
          .then((action) => {
            const response = action.payload;
            if (response && response.status === 201) {
              // User created successfully
              setTimeout(() => {
                dispatch(setShowOptin(false));
                window.scrollTo(0, 0);
                dispatch(setShowResult(true));
                window.plausible('optin');
                window.plausible('result');
              }, 500);
            }
          })
          .catch((error) => {
            // Handle error
            console.log('Error:', error);
          });
      }
    }
  };

  const handleGuest = () => {
    const guestFormData = {
      fname: 'guest',
      lname: 'guest',
      email: 'guest@mail.com',
      subscribe: false,
    };

    dispatch(createUser({ formData: guestFormData, points }))
      .then((action) => {
        const response = action.payload;
        if (response && response.status === 201) {
          // Guest user created successfully
          setTimeout(() => {
            dispatch(setShowOptin(false));
            window.scrollTo(0, 0);
            dispatch(setShowResult(true));
            window.plausible('guest');
            window.plausible('result');
          }, 500);
        }
      })
      .catch((error) => {
        // Handle error
        console.log('Error:', error);
      });
  };

  return (
    <motion.div
      variants={fadeInOut}
      initial="hidden"
      animate="visible"
      exit="exit"
      className={'optin wrapper'}
    >
      <AnimatePresence mode="wait">
        <motion.div
          variants={containerVariants}
          initial="hidden"
          animate="visible"
          exit="exit"
          className="inner-wrapper text-center"
        >
          {' '}
          <div className="optin-content">
            <div className="h-100" initial="hidden" animate="visible" exit="exit">
              <motion.div variants={child} className="m-0 p-0">
                <h1 className="h1-optin">{introData.optinHeader}</h1>
                <div className="info">
                  <p className="text-forest-green info fw-normal mb-1 py-2">
                    {introData.optinDescription}
                  </p>
                </div>
              </motion.div>
              <motion.div variants={child} className="optin-form">
                <div className="form-group d-flex my-3">
                  <input
                    type="text"
                    className={`form-control me-1 me-md-2 ${formErrors.fname ? 'is-invalid' : ''}`}
                    name="fname"
                    placeholder="Ime"
                    value={formData.fname}
                    onChange={handleChange}
                    required
                  />
                  <input
                    type="text"
                    className={`form-control ms-1 ms-md-2 ${formErrors.lname ? 'is-invalid' : ''}`}
                    name="lname"
                    placeholder="Prezime"
                    value={formData.lname}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="form-group">
                  <input
                    type="email"
                    className={`form-control ${
                      formErrors.email || unvalidEmail ? 'is-invalid' : ''
                    }`}
                    name="email"
                    placeholder="Email adresa"
                    value={formData.email}
                    onChange={handleChange}
                    required
                  />
                  {formErrors.fname || formErrors.lname || formErrors.email ? (
                    <div className="invalid-feedback">
                      <p className="warning-text mt-2">Unesite podatke koji nedostaju.</p>
                    </div>
                  ) : unvalidEmail ? (
                    <div className="invalid-feedback">
                      <p className="warning-text">E-adresa nije ispravna.</p>
                    </div>
                  ) : null}
                </div>
                <div className="mt-3 text-start">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name="subscribe"
                      id="subscribe-check"
                      checked={formData.subscribe}
                      onChange={handleChange}
                    />

                    <label className="form-check-label" htmlFor="subscribe-check">
                      {introData.subscribe}
                    </label>
                  </div>
                </div>
              </motion.div>
              <motion.div variants={child} className="optin-btn-wrapper">
                <button onClick={handleUser} className="btn btn-main">
                  {introData.cta}
                </button>
                <button onClick={handleGuest} className="guest-result btn btn-sm mt-2">
                  {introData.cta_guest}
                </button>
              </motion.div>
            </div>
          </div>
        </motion.div>
      </AnimatePresence>
    </motion.div>
  );
};

export default Optin;
