export function importAll(r) {
  let images = {};
  r.keys().map((item) => {
    return (images[item.replace('./', '')] = r(item));
  });
  return images;
}

// Email validation
export const isValidEmail = (email) => {
  const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/u;
  return emailRegex.test(email);
};

// Form validation
export const validateForm = (form) => {
  const formErrors = {};

  if (form.lname.trim() === '') {
    formErrors.lname = true;
  }

  if (form.fname.trim() === '') {
    formErrors.fname = true;
  }

  if (form.email.trim() === '') {
    formErrors.email = true;
  }

  return formErrors;
};

// Social share
export const shareContent = (platform, title, url, text, image) => {
  window.plausible('social_share');

  switch (platform) {
    case 'facebook':
      // Facebook Share
      window.open(
        `https://www.facebook.com/sharer.php?u=${encodeURIComponent(
          url
        )}&quote=${encodeURIComponent(text)}`,
        '_blank'
      );
      break;

    case 'twitter':
      // Twitter Share
      const twitterUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(
        text
      )}&url=${encodeURIComponent(url)}`;
      window.open(twitterUrl, '_blank');
      break;

    case 'messenger':
      // Facebook Messenger Share
      const fbMessengerUrl = `fb-messenger://share/?link=${encodeURIComponent(url)}`;
      window.open(fbMessengerUrl, '_blank');
      break;

    case 'whatsapp':
      // WhatsApp Share
      const whatsappUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(
        text
      )}%20${encodeURIComponent(url)}`;
      window.open(whatsappUrl, '_blank');
      break;

    default:
      // Default share behavior
      if (navigator.share) {
        navigator
          .share({ title, url, text, image })
          .then(() => {
            //console.log("Thanks for sharing!");
          })
          .catch(console.error);
      }
      break;
  }
};
